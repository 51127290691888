<template>
  <div>
    <c-table
      ref="table"
      title="위원회/검토자 목록"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="moc.committees"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&!disabled"
      selection="multiple"
      rowKey="sopChangeCommitteeMemberId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" :showLoading="false" label="추가" icon="add" @btnClicked="addComittee" />
          <c-btn 
            v-if="editable&&!disabled" 
            :isSubmit="isSaveComittee"
            :url="saveComitteeUrl"
            :param="grid.saveData"
            mappingType="PUT"
            label="저장" 
            icon="save" 
            @beforeAction="saveComittee"
            @btnCallback="saveComitteeCallback"/>
          <c-btn v-if="editable&&!disabled" label="삭제" icon="remove" @btnClicked="removeComittee" />
        </q-btn-group>
      </template>
    </c-table>

    <c-table
      ref="table1"
      class="q-mt-sm"
      title="검토필요항목"
      :columns="grid2.columns"
      :gridHeight="grid2.height"
      :data="moc.committeeReviewItems"
      :filtering="false"
      :usePaging="false"
      :columnSetting="false"
      :editable="editable&&!disabled"
      selection="multiple"
      rowKey="sopChangeCommitteeReviewItemId"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" :showLoading="false" label="추가" icon="add" @btnClicked="addReviewItem" />
          <c-btn 
            v-if="editable&&!disabled" 
            :isSubmit="isSaveReviewItem"
            :url="saveReviewItemUrl"
            :param="grid2.saveData"
            mappingType="PUT"
            label="저장" 
            icon="save" 
            @beforeAction="saveReviewItem"
            @btnCallback="saveReviewItemCallback"/>
          <c-btn v-if="editable&&!disabled" label="삭제" icon="remove" @btnClicked="removeReviewItem" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import { uid } from 'quasar';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'committee-nreviewer',
  props: {
    moc: {
      type: Object,
      default: () => ({
        sopMocId: '',  // MOC 일련번호
        mocTypeCd: 'MT00000001',  // 변경구분_공통코드(정상/비상/임시)
        mocStepCd: '',  // 변경관리 단계
        committees: [],
        committeeReviewItems: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [],
        height: '300px',
        saveData: {
          sopMocId: '',
          committees: [],
        },
      },
      grid2: {
        columns: [
          {
            name: 'reviewItemName',
            field: 'reviewItemName',
            label: '검토항목',
            align: 'left',
            style: 'width:250px',
            sortable: false,
            type: 'text',
          },
          {
            name: 'confirmUserName',
            field: 'confirmUserName',
            label: '확인자',
            align: 'center',
            style: 'width:100px',
            type: 'user',
            userId: 'confirmUserId',
            sortable: false,
            value: '',
            setHeader: true,
          },
          {
            name: 'reviewCompleteRequestDate',
            field: 'reviewCompleteRequestDate',
            label: '검토완료요구일자',
            align: 'center',
            style: 'width:100px',
            type: 'date',
            sortable: false,
            value: '',
            setHeader: true,
          },
          {
            name: 'reviewUserName',
            field: 'reviewUserName',
            label: '검토자',
            align: 'center',
            style: 'width:100px',
            type: 'user',
            userId: 'reviewUserId',
            sortable: false,
            value: '',
            setHeader: true,
          },
        ],
        height: '400px',
        saveData: {
          sopMocId: '',
          committeeReviewItems: [],
        },
      },
      isSaveComittee: false,
      isSaveReviewItem: false,
      saveComitteeUrl: '',
      deleteComitteeUrl: '',
      saveReviewItemUrl: '',
      deleteReviewItemUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveComitteeUrl = transactionConfig.sop.moc.committee.save.url
      this.deleteComitteeUrl = transactionConfig.sop.moc.committee.delete.url
      this.saveReviewItemUrl = transactionConfig.sop.moc.committeeReviewItem.save.url
      this.deleteReviewItemUrl = transactionConfig.sop.moc.committeeReviewItem.delete.url
      // code setting
      this.$comm.getComboItems('MOC_ROLE_CD').then(_result => {
        this.$comm.getComboItems('COM_INSIDE_OUTSIDE_CD').then(inout => {
          this.grid.columns = [
            {
              name: 'mocRoleCd',
              field: 'mocRoleCd',
              label: '역할',
              align: 'center',
              type: 'select',
              style: 'width:100px',
              comboItems: _result,
              sortable: false,
            },
            {
              name: 'comInsideOutsideCd',
              field: 'comInsideOutsideCd',
              label: '내외부구분',
              align: 'center',
              style: 'width:100px',
              type: 'select',
              comboItems: inout,
              none: true,
              sortable: false,
            },
            {
              name: 'userName',
              field: 'userName',
              label: '성명',
              align: 'center',
              type: 'user',
              userId: 'userId',
              style: 'width:100px',
              sortable: false,
              isInout: true,
              inoutCol: 'comInsideOutsideCd',
              in: '1',
              out: '2',
              relationCols: ['deptCd', 'deptName'],
            },
            {
              name: 'deptName',
              field: 'deptName',
              label: '부서',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            // {
            //   name: 'positionNm',
            //   field: 'positionNm',
            //   label: '직책',
            //   align: 'center',
            //   sortable: true
            // },
            // {
            //   name: 'dutyNm',
            //   field: 'dutyNm',
            //   label: '직위',
            //   align: 'center',
            //   sortable: true
            // },
          ]
        });
      });
      // list setting
    },
    addComittee() {
      this.moc.committees.splice(0, 0, {
        sopChangeCommitteeMemberId: uid(),  // 변경위원 번호
        sopMocId: this.moc.sopMocId,  // MOC 일련번호
        comInsideOutsideCd: '1',  // 내외부 구분
        userId: '',  // 위원id_내부
        userName: '',  // 성명
        deptName: '',  // 부서명
        mocRoleCd: null,  // 역할_코드 : 위원장/위원
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        editFlag: 'C'
      })
    },
    removeComittee() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$http.url = this.deleteReviewItemUrl;
        this.$http.type = 'DELETE';
        this.$http.param = {
          data: Object.values(selectData),
        };
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.$_.forEach(selectData, item => {
            this.moc.committees = this.$_.reject(this.moc.committees, item);
          })
          this.$refs['table'].$refs['compo-table'].clearSelection();
          // this.$emit('saveCallback', this.moc.sopMocId)
        },);
      }
    },
    saveComittee() {
      if (this.$comm.validTable(this.grid.columns, this.moc.committees)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.grid.saveData.sopMocId = this.moc.sopMocId;
            this.grid.saveData.committees = this.$_.cloneDeep(this.moc.committees);
            
            this.isSaveComittee = !this.isSaveComittee
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveComitteeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveCallback', this.moc.sopMocId)
    },
    addReviewItem() {
      this.moc.committeeReviewItems.splice(0, 0, {
        sopChangeCommitteeReviewItemId: uid(),  // 검토필요 항목 번호
        sopMocId: this.moc.sopMocId,  // MOC 일련번호
        reviewItemName: '',  // 검토 항목명
        reviewCompleteRequestDate: '',  // 검토완료요구일
        confirmUserId: '',  // 확인자 id
        reviewUserId: '',  // 검토자 id
        reviewContents: '',  // 검토내용
        reviewCompleteDate: '',  // 검토완료일
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        editFlag: 'C'
      })
    },
    removeReviewItem() {
      let selectData = this.$refs['table1'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$http.url = this.deleteComitteeUrl;
        this.$http.type = 'DELETE';
        this.$http.param = {
          data: Object.values(selectData),
        };
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.$_.forEach(selectData, item => {
            this.moc.committeeReviewItems = this.$_.reject(this.moc.committeeReviewItems, item);
          })
          this.$refs['table1'].$refs['compo-table'].clearSelection();
        },);
      }
    },
    saveReviewItem() {
      if (this.$comm.validTable(this.grid2.columns, this.moc.committeeReviewItems)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.grid2.saveData.sopMocId = this.moc.sopMocId;
            this.grid2.saveData.committeeReviewItems = this.$_.cloneDeep(this.moc.committeeReviewItems);
            
            this.isSaveReviewItem = !this.isSaveReviewItem
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveReviewItemCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveCallback', this.moc.sopMocId)
    },
  }
};
</script>
